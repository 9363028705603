import type { ReactNode } from "react";

import { ProofFooter } from "common/proof_footer";

import RetrievalHeader from "../header";
import Styles from "./index.module.scss";

type Props = {
  children?: ReactNode;
  hideFrameFooter?: boolean;
};

export function VerifyFrame({ children, hideFrameFooter }: Props) {
  return (
    <div className={Styles.frame}>
      <RetrievalHeader />
      <main className={Styles.content}>{children}</main>
      {!hideFrameFooter && <ProofFooter />}
    </div>
  );
}
