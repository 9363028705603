import { Navigate, Outlet, createBrowserRouter, RouterProvider, useMatch } from "react-router-dom";

import App from "common/app";
import Retrieval from "verify_portal/retrieval";
import RetrievalRequest from "verify_portal/retrieval/request";
import RetrievalDetails from "verify_portal/retrieval/details";
import { CertifyRetrievalDetails } from "verify_portal/retrieval/details/certify_details";
import { useMobileScreenClass } from "common/core/responsive";

const Root = () => {
  const isCertifyPath = Boolean(useMatch("/certify/:uuid"));
  const isMobile = useMobileScreenClass();

  return (
    <App isAuthenticated={false} mobileSupported>
      <Retrieval hideFrameFooter={isCertifyPath && isMobile} showCtaSection={!isCertifyPath}>
        <Outlet />
      </Retrieval>
    </App>
  );
};

const router = createBrowserRouter([
  {
    element: <Root />,
    children: [
      { path: "/", element: <RetrievalRequest /> },
      { path: "/details", element: <RetrievalDetails /> },
      { path: "/certify/:uuid", element: <CertifyRetrievalDetails /> },
      { path: "*", element: <Navigate replace to="/" /> },
    ],
  },
]);

export default () => {
  return <RouterProvider router={router} />;
};

export const __VerifyRouter = Root;
