import type { ReactNode } from "react";

import { CtaSection } from "./cta_section";
import { VerifyFrame } from "./verify_frame";

type Props = {
  children?: ReactNode;
  showCtaSection?: boolean;
  hideFrameFooter?: boolean;
};

function Retrieval({ children, showCtaSection = false, hideFrameFooter = false }: Props) {
  return (
    <VerifyFrame hideFrameFooter={hideFrameFooter}>
      {children}
      {showCtaSection && <CtaSection />}
    </VerifyFrame>
  );
}

export default Retrieval;
