import ActionsSection from "./actions_section";
import FeaturesSection from "./features_section";
import Styles from "./index.module.scss";

export function CtaSection() {
  return (
    <div className={Styles.ctaSection}>
      <div className={Styles.wrapper}>
        <ActionsSection />
        <FeaturesSection />
      </div>
    </div>
  );
}
