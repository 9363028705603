import { memo, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";

import { saveAll, swapExtension } from "util/archive";
import DocumentsProcessing from "assets/images/verify/document-processing.svg";
import SROnly from "common/core/screen_reader";
import LoadingIndicator from "common/core/loading_indicator";
import { useQuery } from "util/graphql";
import { Heading, Paragraph, Substyle } from "common/core/typography";
import { PDFViewer } from "common/pdf/pspdfkit/viewer";
import { IconButton } from "common/core/button/icon_button";
import { LongFormattedDateTime } from "common/core/format/date";
import Button from "common/core/button";
import Icon from "common/core/icon";
import { useMobileScreenClass } from "common/core/responsive";
import { userFullName } from "util/user";
import { IllustrationEmptyState } from "common/core/empty_state";
import { OrganizationTransactionDetailedStatus } from "graphql_globals";
import DocumentError from "assets/images/signer/document-error.svg";
import Svg from "common/core/svg";
import { ProofFooter } from "common/proof_footer";

import PublicRetrievalViewerDetailsQuery, {
  type PublicRetrievalViewerDetails_viewer_retrievePublicTransaction as PublicRetrieval,
} from "./public_retrieval_query.graphql";
import Styles from "./index.module.scss";

type RetrievalProps = {
  retrieval: PublicRetrieval;
};
type DownloadDocumentsButtonProps = {
  retrieval: PublicRetrieval;
  fullWidth?: boolean;
};

const MESSAGES = defineMessages({
  nextDocument: {
    id: "0c6c1a11-15f3-4efc-8cc1-a4e9f7a586be",
    defaultMessage: "Next document",
  },
  previousDocument: {
    id: "98df0807-f523-499c-af28-06d18d845e17",
    defaultMessage: "Previous document",
  },
});

function asArchivePublicRetrieval(retrieval: PublicRetrieval) {
  const { documentBundle } = retrieval;
  const documentData = documentBundle.documents.map((node) => ({
    url: node.finalAsset!.url!,
    name: swapExtension(node.name) || "",
  }));

  return saveAll({
    archiveName: retrieval.name || "Archive",
    files: documentData,
  });
}

function DownloadDocumentsButton({ retrieval, fullWidth }: DownloadDocumentsButtonProps) {
  const [downloading, setDownloading] = useState(false);
  const { documentBundle } = retrieval;

  const downloadAll = useCallback(async () => {
    setDownloading(true);
    await asArchivePublicRetrieval(retrieval);
    setDownloading(false);
  }, [documentBundle]);

  return (
    <Button
      automationId="download-all-button"
      onClick={downloadAll}
      isLoading={downloading}
      buttonSize="large"
      buttonColor="action"
      variant="primary"
      withIcon={{ name: "download", placement: "right" }}
      fullwidth={fullWidth}
    >
      <FormattedMessage
        id="93d66799-073c-4ae3-aa3f-a31a28676812"
        defaultMessage="Download documents"
      />
    </Button>
  );
}

function DetailsSection({ retrieval }: RetrievalProps) {
  const isMobile = useMobileScreenClass();
  const { name, completedAt, documentBundle } = retrieval;
  const { signers } = documentBundle;

  return (
    <div className={Styles.detailsContainer}>
      <Heading level="h1" textStyle="headingFour">
        {name}
      </Heading>
      <div className={Styles.certificationDetails}>
        <Icon name="success" className={Styles.certifyIcon} />
        <Substyle textStyle="headingSix">{userFullName(signers[0])}</Substyle>

        <Paragraph>
          <FormattedMessage
            id="a8cb8207-e7c6-4591-83b0-415172c040ef"
            defaultMessage="IAL2 Certified on "
          />{" "}
          <LongFormattedDateTime value={completedAt} asTimeZone="UTC" />
        </Paragraph>
      </div>
      {!isMobile && <DownloadDocumentsButton retrieval={retrieval} />}
    </div>
  );
}

function DocumentsSection({ retrieval }: RetrievalProps) {
  const intl = useIntl();
  const [currentDocumentIndex, setCurrentDocumentIndex] = useState(0);
  const { documentBundle } = retrieval;

  const documentCount = documentBundle.documents.length;

  const handlePrevious = () => {
    setCurrentDocumentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };
  const handleNext = () => {
    setCurrentDocumentIndex((prevIndex) => Math.min(prevIndex + 1, documentCount - 1));
  };
  const currentDocument = documentBundle.documents[currentDocumentIndex];

  const documents = (
    <div
      className={Styles.pdfContainer}
      data-automation-id="document-item"
      key={currentDocument.name}
    >
      {currentDocument.finalAsset && (
        <PDFViewer className={Styles.pdfViewer} url={currentDocument.finalAsset.url!} />
      )}
      <div className={Styles.documentName}>
        {documentCount > 1 && (
          <IconButton
            name="caret-left"
            label={intl.formatMessage(MESSAGES.previousDocument)}
            onClick={handlePrevious}
            disabled={currentDocumentIndex === 0}
          />
        )}
        <Paragraph>
          <SROnly>
            <FormattedMessage
              id="fcf5cd46-b1a6-46d0-a0eb-e773532ed394"
              defaultMessage="Current document:"
            />
          </SROnly>
          {currentDocument.name}
        </Paragraph>
        {documentCount > 1 && (
          <IconButton
            name="caret-right"
            label={intl.formatMessage(MESSAGES.nextDocument)}
            onClick={handleNext}
            disabled={currentDocumentIndex === documentCount - 1}
          />
        )}
      </div>
    </div>
  );

  return (
    <div className={Styles.documentsContainer} data-automation-id="documents-section">
      <Heading level="h2" textStyle="allCapsLabel" textColor="subtle">
        <FormattedMessage
          id="53779905-d57d-48d0-a43e-0ea28e919d8c"
          defaultMessage="{documentCount} {documentCount, plural, one {Document} other {Documents}}"
          values={{ documentCount }}
        />
      </Heading>

      {documents}
    </div>
  );
}

const CertifyRetrievalErrorState = () => (
  <div className={Styles.certifyRetrievalErrorState}>
    <Svg src={DocumentError} alt="" width={171} />
    <Heading level="h1" textStyle="headingFour">
      <FormattedMessage
        id="e641a714-d75c-4ae6-bc45-c75a74f3f151"
        defaultMessage="Documents not found"
      />
    </Heading>
    <Paragraph textAlign="center" className={Styles.errorText}>
      <FormattedMessage
        id="4e9ad9a3-d30b-424e-95fe-0d9f04982297"
        defaultMessage="Please check your URL or scan the QR code again."
      />
    </Paragraph>
  </div>
);

const CertifyRetrievalEmptyState = () => (
  <div className={Styles.certifyRetrievalEmptyState}>
    <IllustrationEmptyState
      heading={{ level: "h1", text: "Documents not yet available" }}
      bodyText="You will be able to view and download the documents once they have been completed. Please check back later."
      image={DocumentsProcessing}
    />
  </div>
);

const CertifyRetrievalCompleteState = memo(
  ({ retrieval, isMobile }: { retrieval: PublicRetrieval; isMobile: boolean }) => (
    <div className={Styles.certifyRetrievalContainer}>
      <DetailsSection retrieval={retrieval} />
      <DocumentsSection retrieval={retrieval} />
      {isMobile && (
        <div className={Styles.mobileButtonContainer}>
          <DownloadDocumentsButton fullWidth retrieval={retrieval} />
        </div>
      )}
    </div>
  ),
);

function CertifyRetrievalDetails() {
  const isMobile = useMobileScreenClass();
  const { uuid } = useParams();

  const { data, loading, error } = useQuery(PublicRetrievalViewerDetailsQuery, {
    variables: { uuid: uuid! },
  });

  if (loading) {
    return <LoadingIndicator />;
  }

  const publicRetrieval = data?.viewer.retrievePublicTransaction;
  const errorState = error || !publicRetrieval;
  const transactionIsComplete =
    publicRetrieval?.detailedStatus === OrganizationTransactionDetailedStatus.COMPLETE;

  return (
    <>
      {errorState ? (
        <CertifyRetrievalErrorState />
      ) : !transactionIsComplete ? (
        <CertifyRetrievalEmptyState />
      ) : (
        <CertifyRetrievalCompleteState retrieval={publicRetrieval} isMobile={isMobile} />
      )}
      {isMobile && <ProofFooter hasMobileFooterButton={!errorState && transactionIsComplete} />}
    </>
  );
}

const CertifyRetrievalDetailsWithMemo = memo(CertifyRetrievalDetails);

export { CertifyRetrievalDetailsWithMemo as CertifyRetrievalDetails };
