import { memo } from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";

import Link from "common/core/link";
import { MARKETING_HOST } from "constants/marketing";
import { FullLogo } from "common/core/logo";

import Styles from "./index.module.scss";

const MESSAGES = defineMessages({
  logoAlt: {
    id: "3f14f228-41eb-4fef-b9bd-30a3b9cb2de2",
    defaultMessage: "Proof.com home page",
  },
});

function RetrievalHeader() {
  const intl = useIntl();

  return (
    <header className={Styles.retrievalHeader}>
      <Link underlined={false} href={MARKETING_HOST}>
        <FullLogo className={Styles.logo} alt={intl.formatMessage(MESSAGES.logoAlt)} />
      </Link>
      <Link underlined={false} href={MARKETING_HOST} className={Styles.link}>
        <FormattedMessage
          id="1b72ac1b-df1a-42b6-8970-3f5cdc35f352"
          defaultMessage="What is Proof?"
        />
      </Link>
    </header>
  );
}

export default memo(RetrievalHeader);
